import { getCaseSmsCode, getCase, saveCase } from "@/api/info"
import { validatePhone, validateEmail } from '@/utils/validator.js'
import { Toast } from 'vant';
import { Session } from "../../utils/storage"
import screenfull from 'screenfull'
import {
  Message
} from 'element-ui';


export default {
  data() {
    return {
      loading: false,
      dialogWidth: '50%',
      formLabelWidth: '110px',

      form: {
        userName: '', // 姓名
        mobilePhone: '', // 手机号
        smsCode: "", // 验证码
        shortName: "", // 公司名称
        email: "", // 邮箱
      },
      rules: {
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobilePhone: [
          {
            required: true,
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        email: [
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ],
        shortName: [
          { required: true, message: '请输入单位名称', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
      },

      msg: '获取验证码',
      interval: null,
      countDownNumber: 0
    };

  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "查看方案"
    },
    cancelText: {
      type: String,
      default: "取消"
    },
    comfirmText: {
      type: String,
      default: "提交"
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    comfirmDisabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "30%"
    },
    showClose: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    screenWidth: {
      default: document.body.clientWidth
    }
  },
  methods: {
    comfirm() {
      this.$refs['userInfo'].validate((valid) => {
        if (valid) {
          this.loading = true

          getCase(this.form).then(res => {
            if (res.success) {

              var info = navigator.userAgent
              var isPhone = /mobile|iphone|android|ipod/i.test(info)
              if (!isPhone) {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000,
                  countDown: 1
                })
              } else {
                Toast.success({ message: '提交成功', duration: 1000 });
              }

              Message.closeAll()

              Session.set('userInfo', this.form)

              this.$refs['userInfo'].resetFields();
              this.loading = false

              this.$emit("cancel", true);
            } else {

              this.loading = false
            }
          })
        } else {
          return false
        }
      })

    },
    handleGetSmsCode() {
      if (!this.form.mobilePhone) {
        var info = navigator.userAgent
        var isPhone = /mobile|iphone|android|ipod/i.test(info)
        if (!isPhone) {
          this.$message({
            message: '手机号不能为空',
            type: 'error'
          })
        } else {
          Toast.fail('手机号不能为空');
        }

        return
      }
      const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (!reg.test(this.form.mobilePhone)) {
        var info = navigator.userAgent
        var isPhone = /mobile|iphone|android|ipod/i.test(info)
        if (!isPhone) {
          this.$message({
            message: '手机号格式错误',
            type: 'error'
          })
        } else {
          Toast.fail('手机号格式错误');
        }

        return
      }
      if (this.interval) return
      this.countDownNumber = 60
      // 开始倒计时
      // 重新发送
      this.interval = setInterval(() => {
        this.countDownNumber -= 1

        this.msg =
          this.countDownNumber != 0
            ? '重新发送' + this.countDownNumber + 's'
            : '重新发送'

        if (this.countDownNumber <= 0) {
          clearInterval(this.interval)
          this.interval = null
        }
      }, 1000)


      getCaseSmsCode({ mobile: this.form.mobilePhone }).then((res) => {
        // console.log(res)
      })
    },
    cancel() {

      Message.closeAll()
      clearInterval(this.interval)
      this.interval = null
      this.msg = '获取验证码'
      this.countDownNumber = 0

      this.$refs['userInfo'].resetFields();

      this.$emit("cancel", false);
    },
    setDialogWidth() {
      this.formLabelWidth = '110px'

      if (this.screenWidth <= 768) {
        this.dialogWidth = '100%'
      } else if (this.screenWidth <= 1024) {
        this.dialogWidth = '60%'
      } else {
        this.dialogWidth = '50%'
      }
    }
  },
  watch: {
    screenWidth: {
      handler(n, o) {
        this.setDialogWidth()
      },
      immediate: true
    }
  },

  beforeDestroy() {
    clearInterval(this.interval)
    this.interval = null
  },
};
