import { makeAppointment, findModule } from "@/api/appointmentt"
import { validatePhone } from '@/utils/validator.js'
import { Toast } from 'vant';
export default {
  data() {
    return {
      loading: false,
      dialogWidth: '50%',
      formLabelWidth: '110px',

      form: {
        userName: '',
        mobilePhone: '',
        shortName: "",
        programme: []
      },
      rules: {
        userName: [{ required: true, message: '请输入正确的姓名', trigger: 'blur' }],
        mobilePhone: [
          {
            required: true,
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        shortName: [
          { required: true, message: '请输入单位名称', trigger: 'blur' }
        ],
        programme: [
          { required: true, message: '请选择关注模块', }
        ]
      },
      checkList: ['选中且禁用', '复选框 A'],
      programmeList: [],
    };

  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "标题"
    },
    cancelText: {
      type: String,
      default: "取消"
    },
    comfirmText: {
      type: String,
      default: "提交"
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    comfirmDisabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "30%"
    },
    showClose: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    screenWidth: {
      default: document.body.clientWidth
    }
  },
  mounted() {
    this.getprogrammeList()
  },
  methods: {
    getprogrammeList() {
      findModule().then(res => {
        if (!res.success) return
        const reqData = res.data

        this.programmeList = reqData
      })
    },
    comfirm() {
      this.$refs['yuyue'].validate((valid) => {
        if (valid) {
          this.loading = true
          let params = {
            userName: this.form.userName,
            mobilePhone: this.form.mobilePhone,
            shortName: this.form.shortName,
            serviceType: this.form.programme.join(',')
          }
          makeAppointment(params).then(res => {
            if (res.success) {

              var info = navigator.userAgent
              var isPhone = /mobile|iphone|android|ipod/i.test(info)
              if (!isPhone) {
                this.$message({
                  message: '预约成功',
                  type: 'success'
                })
              } else {
                Toast.success('预约成功');
              }


              this.$refs['yuyue'].resetFields();


              this.loading = false

              this.$emit("cancel");
            } else {

              this.loading = false
            }
          })
        } else {
          return false
        }
      })

    },
    cancel() {
      this.$refs['yuyue'].resetFields();

      this.$emit("cancel");
    },
    setDialogWidth() {
      this.formLabelWidth = '110px'

      if (this.screenWidth <= 768) {
        this.dialogWidth = '100%'
      } else if (this.screenWidth <= 1024) {
        this.dialogWidth = '60%'
      } else {
        this.dialogWidth = '50%'
      }
    }
  },
  watch: {
    screenWidth: {
      handler(n, o) {
        this.setDialogWidth()
      },
      immediate: true
    }
  },
};
